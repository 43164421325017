import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Row, Col, Container, Card } from "reactstrap"
import {
  // Modal,
  // ModalFooter,
  // ModalHeader,
  // ModalBody,
  Input,
  Dropdown,
  divider,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Label,
} from "reactstrap"
import axios from "axios"
import Breadcrumb from "components/Common/Breadcrumb"

import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { Form, Modal, Button, Select, InputNumber, DatePicker, TimePicker} from "antd"

function CatalogInteration(props) {
  const [form] = Form.useForm()
  const [typesData, setTypesData] = useState([])

  const [openModel, setOpenModel] = useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const history = useHistory()
  const { Option } = Select

  const [data, setData] = useState(history?.location?.state)

  const openModelState = () => {
    setOpenModel(true)
  }

  const [fetchIntegration, setFetchIntegration] = useState(null)
  const [selectedAuthType, setSelectedAuthType] = useState(null)
  const [auth_url, setAuth_url] = useState(null);
  const [integrationFormdata, setintegrationFormdata] = useState({
    properties: {},
    auth_properties: {},
  })

  const handleAuthTypeChange = e => {
    const hello = data.auth_settings.filter(a => a.auth_type_display_name === form.getFieldValue("auth_type") && a)
    setSelectedAuthType([...hello])
  }

  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("okta-token-storage"))
  )

  const handleAuthProperties = (value, name) => {
    let data = integrationFormdata;
    data.auth_properties[name] = value;
    setintegrationFormdata(data);
  }

  const handleProperties = (value, name) => {
    let data = integrationFormdata;
    data.properties[name] = value;
    setintegrationFormdata(data);
  }

  const headers = {
    Authorization: "Bearer " + token.accessToken.accessToken,
    Accept: "application/json"
  }

  const getMyIntegrationsList = async () => {
    const res = axios.get(`${process.env.REACT_APP_BASE_API_CALL_URL}`+'integration/settings', { headers: headers})
      .then(
        async ({ data }) => {
          var Obj = { data, returnData: [] };
          await Obj.data.forEach((item) => {
            if (
              Obj.hasOwnProperty('returnData') &&
              Obj.returnData.hasOwnProperty(item.integration_info.type)
            ) {
              Obj.returnData[item.integration_info.type].push(item);
            } else {
              Obj.returnData[item.integration_info.type] = [item];
            }
          });
          // return Obj;
          console.log('getMyIntegrationsList res', Obj);
        }
      )
  }
  const handleSubmit = async values => {

    let formData1 = {}
    formData1.integration_id = data.id
    formData1.auth_type = data.auth_settings[0].auth_type
    formData1.label = values.label
    formData1.properties = integrationFormdata.properties
    formData1.auth_properties = integrationFormdata.auth_properties
    const res = await axios.post(`${process.env.REACT_APP_BASE_API_CALL_URL}`+"integration", formData1 ,{headers: headers})
    .then(({ data }) => {
      getMyIntegrationsList()
        if (data && data.auth_url !== null) {
          setAuth_url(data.auth_url);
        }else{
          close()
        }
    }).catch((data) => {
      return Promise.reject(data);
    });
  }
  const close = () => {
    form.resetFields()
    setAuth_url(null)
    setOpenModel(false)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Projects Grid | Layerity</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          
          <Breadcrumb  breadcrumbItem="Add Integration" to="/projects-grid"  data={[{name:'Integrations',path:"/projects-grid"}, {name:'Catalog',path:"/projects-grid"},{name:'add Integration',path:"#"}]}/>


          <hr style={{ height: 2, color: "black" }} />
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col xl={10} md={6} className="integration_img">
              <img src={data?.icon_url} alt={data?.title} />
            </Col>
            <Col xl={2}>
              <Button onClick={openModelState}>Add To Layerity</Button>
            </Col>
          </Row>
          <Row>
            <Col xl={12} style={{ marginTop: "15px" }}>
              <p style={{ fontSize: "14px" }}>{data?.description}</p>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col xl={6}>
              <h4 style={{ color: "black" }}>Key Features:</h4>
              <p>No Data</p>
            </Col>
            <Col xl={6}>
              <h4 style={{ color: "black" }}>Installation and user guide:</h4>
              <p>No Data</p>
            </Col>
          </Row>
          {openModel && (
            <Modal
              visible={openModel}
              onCancel={close}
              // style={{ marginTop: "100px" }}
              title={data.title}
              footer={[
                <>
                  <Button onClick={close}>cancel</Button>
                  <Button
                    key="submit"
                    disabled={auth_url ? true : false}
                    type="primary"
                    htmlType="submit"
                    form="addform"
                  >
                    save
                  </Button>
                </>
              ]}
            >
              {auth_url ? (
                <div>
                <p>
                  Please{" "}
                  <a href={auth_url} target="_blank" rel="noreferrer">
                    click
                  </a>{" "}
                  here.
                </p>
              </div>
              ) : (
              <Form
                form={form}
                id="addform"
                onFinish={handleSubmit}
                labelCol={{ span: 24 }}
              >
                <Row>
                  <Col xl={12}>
                    <Form.Item label="Label" name="label">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {data?.connection_properties &&
                  data?.connection_properties.map((item,index) => {                    
                    if (item?.value_type === "STRING") {
                      return (
                        <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }} >
                          <Input />
                        </Form.Item>
                      )
                    } else if (item.value_type === "NUMBER") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }}>
                            <InputNumber />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "DATE") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }}>
                            <DatePicker />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "TIME") {
                      return (
                        <>
                        <Form.Item
                          label={item.label}
                          name={item.name}
                          initialvalues={"00:00:00", "HH:mm:ss"}
                        >
                          <TimePicker
                            onChange={(e) => {
                              // handleChange();
                              handleProperties(e, item.name);
                            }}
                          />
                        </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "ENUM") {
                      return (
                        <>
                          <Form.Item label={item.label} name={item.name} required>
                            <Select
                              allowClear
                              placeholder={item.name}
                              optionFilterProp="children"
                              onChange={(e) => {
                                // handleChange();
                                handleProperties(e, item.name);
                              }}
                            >
                              {item.allowed_values &&
                                item.allowed_values.map((val, key) => (
                                  <Option key={key} value={key}>
                                    {val}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }}>
                          <Input />
                        </Form.Item>
                        </>
                      )
                    }
                  })}
                {data && data.auth_settings && (
                  <Row>
                    <Col xl={12}>
                      <Form.Item label="Select Auth Type" name="auth_type" initialvalues="OAuth-v2">
                        <Select
                          style={{
                            width: "100%",
                            padding: "5px",
                            borderColor: "gray",
                            fontSize: "16px",
                          }}
                        onChange={(e) => handleAuthTypeChange(e)}
                        >
                          {data &&
                            data?.auth_settings.map((item, index) => (
                              <Option
                                value={item.auth_type_display_name}
                                key={index}
                              >
                                {item.auth_type_display_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {selectedAuthType &&
                  selectedAuthType[0].auth_property_definitions.map(item => {
                    if (item?.value_type === "STRING") {
                      return (
                        <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                          <Input />
                        </Form.Item>
                      )
                    } else if (item.value_type === "NUMBER") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                            <InputNumber />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "DATE") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                            <DatePicker />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "TIME") {
                      return (
                        <>
                        <Form.Item
                          label={item.label}
                          name={item.name}
                          initialvalues={"00:00:00", "HH:mm:ss"}
                        >
                          <TimePicker
                            onChange={(e) => {
                              // handleChange();
                              handleAuthProperties(e, item.name);
                            }}
                          />
                        </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "ENUM") {
                      return (
                        <>
                          <Form.Item label={item.label} name={item.name} required>
                            <Select
                              allowClear
                              // placeholder={item.name}
                              optionFilterProp="children"
                              onChange={(e) => {
                                // handleChange();
                                handleAuthProperties(e, item.name);
                              }}
                            >
                              {item.allowed_values &&
                                item.allowed_values.map((val, key) => (
                                  <Option key={key} value={key}>
                                    {val}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                          <Input />
                        </Form.Item>
                        </>
                      )
                    }
                  })}
              </Form>
              )}
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default CatalogInteration