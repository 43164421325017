import React from 'react'
import { Spinner } from 'reactstrap'
import { Spin } from 'antd'
import './Loader.scss'

export default function Loader() {
  
  return (
      <div className='loader-design'>
       <Spinner animation="border" role="status" />
      
      
      </div>
  )
}
