import { SPEND_OVERVIEW_SUCCESS } from "./actionType";
import { SPEND_OVERVIEW_FAILED } from "./actionType";
import { SPEND_OVERVIEW } from "./actionType";

export const SPEND_OVERVIEW_REQUEST = (id) => {
    return ({
        type : SPEND_OVERVIEW,
        id : id
    })
}


export const SPEND_OVERVIEW_SUCCESS_REQUEST = (data) => {
return({
    type : SPEND_OVERVIEW_SUCCESS,
    data : data 
})
}


export const SPEND_OVERVIEW_FAILED_REQUEST = (data) => {
    return({
        type : SPEND_OVERVIEW_FAILED,
        data : data
    })
}