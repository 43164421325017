import {USER_INFO} from './actionType';

const initialState = {
    user:null
}

const UserInformation = (state=initialState,action) => 
{
    switch(action.type)
    {
        case USER_INFO:
            state.user = action.paylaod;
            return state;
        break;
        
        default:
            return state;

    }
}

export default UserInformation;
