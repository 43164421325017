import React,{useState} from 'react'
import { Modal, Button } from 'antd';
import ReactApexChart from 'react-apexcharts'


const Graphs = (props) =>  {

  const [visible,setVisible] = useState(true)


  const handleCancel = () => {

    setVisible(false)  
    props?.onChange(false)      
  }

  const handleOk = () =>{
    setVisible(false)
    props?.onChange(false)      
  }

  var options = {
    series: [{
    name:props?.data?.title,
    data: props?.data?.data
  }],
    chart: {
      type: 'line',
      height: 360,
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    plotOptions: {
      line: {
        barHeight: '100%',
        columnWidth: '55%',
        distributed: false,
        horizontal: false,
        dataLabels: {
          position: 'center'
        },
        borderRadius: 2,
      }
    },
    dataLabels: {
      enabled: false,
      textAnchor: 'center',
      style: {
        colors: ['#000'],
        fontSize: 8
      },
      formatter: function (val, opt) {
        return val
      },
      offsetX: -8,
    },                    
    yaxis: {
      labels: {
        show: true
      }
    },
    xaxis: {
      labels: {
        show: true
      }
    },                    
    legend: {
      show: false
    },
    
  }



  return (
    <div>
     <Modal title={props?.data?.title} visible={visible}  onOk={handleOk} onCancel={handleCancel} footer={false}>
        <ReactApexChart options={options} series={options?.series} />
      </Modal>
    </div>
  )
}


export default Graphs;