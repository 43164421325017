import { SHOWLOADING,HIDELOADING } from "./actionType";

const initialState = {
    loading:false
}

const LoaderReducer = (state=initialState,action)  => 
{
switch(action.type)
{
    case SHOWLOADING:
      console.log("this is show loading part")
      state.loading=true        
      return state
      break;
    
      case HIDELOADING:
      console.log("this is hide loading part")
      state.loading = false
      return state;
      break;
    default:
      return state;
    }
}

export default LoaderReducer;