import { WELCOMEDATA_REQUEST_SUCCESS, WELCOMEDATA_REQUEST_FAILED } from "./actionType"


const initialState = {
    WelcomeApiData : null,
    loading:true
}

const Dash = (state=initialState,actions) => {


   // console.log('the action is ',actions)
    switch(actions.type)
    {
        case WELCOMEDATA_REQUEST_SUCCESS:            
            return {
                ...state,
                WelcomeApiData : actions.data,
                loading:false
            }   
        case WELCOMEDATA_REQUEST_FAILED:
            return{
                ...state,
                WelcomeApiData:null,
                loading:true
            }         
        default:
            return state;
    }
}


export default Dash;