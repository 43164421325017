import { call , takeLatest , put } from "redux-saga/effects";
import { GET_SETTING_INTEGRATION_FAILED_REQUEST } from "./action";
import { GET_SETTING_INTEGRATION_SUCCESS_REQUEST } from "./action";
import { GET_SETTING_INTEGRATION } from "./actionType";


import axios from 'axios';

const getSettingIntegartionApi = async () => {

    const token = JSON.parse(localStorage.getItem('okta-token-storage'))
    const response = await axios.get(process.env.REACT_APP_BASE_API_CALL_URL+'integration/settings',{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
    Accept: 'application/json'}}) 
    return response
}


function*  getSettingData()
{
    try
    {
        var response = yield call(getSettingIntegartionApi)
        console.log(response)
        yield put(GET_SETTING_INTEGRATION_SUCCESS_REQUEST(response))
    }
    catch(err)
    {
        console.log(err)
        yield put(GET_SETTING_INTEGRATION_FAILED_REQUEST(err))
    }
}

function* settingIntegrationSaga()
{
    yield takeLatest(GET_SETTING_INTEGRATION,getSettingData)
} 


export default settingIntegrationSaga;