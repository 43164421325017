import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import { notification } from "antd";
import axios from "axios";
import {connect} from 'react-redux';
import {compose} from 'redux';
import {GET_SETTING_INTEGRATION_REQUEST} from '../../store/settingIntegration/action';
import SettingIntegrationReducer from  '../../store/settingIntegration/reducer'
import Loader from '../../utils/Loader'
import { Empty } from 'antd';
import * as moment from "moment";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  // Modal,
  // ModalHeader,
  ModalBody,
  // Form,
  Input,
  FormFeedback,
  Label,
  // ModalFooter,
  // Button,
  Tooltip,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";
import { Form, Modal, Button, Select, InputNumber, DatePicker, TimePicker} from "antd"
import {
  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
  updateProject as onUpdateProject,
  deleteProject as onDeleteProject,
} from "store/actions";



//redux
import { useSelector, useDispatch } from "react-redux";

// Note :: -> in this we are not using the dispatch,useSelection so ignore the redux portion
// just doing this with the help of the component state variable
  
const ProjectsList = (props) => {
  const [form] = Form.useForm()
  const { Option } = Select
  const [openModel, setOpenModel] = useState(false)
  const [ activeID, setActiveID ] = useState(null)
  const dispatch = useDispatch();
  const [searchData,setSearchData] = useState('')
  const [project, setProject] = useState();
  const [NoDataFound,setNoDataFound] = useState(false)
  const [auth_url, setAuth_url] = useState(null);
  const [selectedAuthType, setSelectedAuthType] = useState(null);
  const [projectSelectedData,setProjectSelectedData] = useState(null)
  const [integrationFormdata, setintegrationFormdata] = useState({
    properties: {},
    auth_properties: {},
  });
  // console.log(selectedAuthType, 'selectedAuthType');
  const handleAuthTypeChange = e => {
    const AuthValue = project.integration_info.auth_settings.filter(a => a.auth_type_display_name === form.getFieldValue("auth_type") && a)
    setSelectedAuthType([...AuthValue])
  }

  const [token, setToken] = useState(JSON.parse(localStorage.getItem("okta-token-storage")))

  const handleAuthProperties = (value, name) => {
    let data = integrationFormdata;
    data.auth_properties[name] = value;
    setintegrationFormdata(data);
  }

  const handleProperties = (value, name) => {
    let data = integrationFormdata;
    data.properties[name] = value;
    setintegrationFormdata(data);
  }

  let headers = {
    Authorization: "Bearer " + token.accessToken.accessToken,
    Accept: "application/json"
  }

  const handleSubmit = async values => {
    const auth_prop_values = {"access_token": null, "expires_at": null, "oauth_refresh_token": null}
    let newForm = {}
    
    
    newForm.departments = projectSelectedData?.departments ? projectSelectedData?.departments : []
    newForm.connection_prop_values = projectSelectedData?.connection_prop_values ? projectSelectedData?.connection_prop_values : {}
    newForm.service_prop_values = projectSelectedData?.service_prop_values ? projectSelectedData?.service_prop_values : {}
    newForm.auth_prop_values = auth_prop_values
    
    newForm.label = values.label



    const res = await axios.put(`${REACT_APP_BASE_API_CALL_URL}integration/` + projectSelectedData.id, newForm ,{headers: headers})
    .then(({ data }) => {
        if (data && data.auth_url !== null) {
          setAuth_url(data.auth_url);          
        }else{
          close()          
          notification.success({message:'added successfully'})
          SettingIntegrationData()
        }
    }).catch((data) => {
      return Promise.reject(data);
    });
    }

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }));
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [projectList, setProjectList] = useState([]);
  //  this is contain the Action id for delete the integration for perfoming the action 
  const [DeletedId,setDeletedId] = useState(null)
  const [FilterData,setFilterData] = useState([])

  // const [deleteModel,setDeleteModel] = useState(false)

  const handleProjectClick = arg => {
    const selectedProject = data.filter(a => a.id === arg.id && a)
    setProject(selectedProject[0])
    console.log(arg)
    setProjectSelectedData(arg)    
    setIsEdit(true);
    setOpenModel(true)
  };

  // const [token,setToken] = useState(JSON.parse(localStorage.getItem('okta-token-storage')))
  const [data,setData] = useState([]);
  const [typesData,setTypesData] = useState([])
  const [TypeRelateData,setTypeRelateData] = useState([])
  const [deleteData,setDeleteData] = useState(false)


  const SettingIntegrationData = async () => {
    props.GET_SETTING_INTEGRATION_REQUEST()
  }

  useEffect(()=>{
    SettingIntegrationData()
  },[])


  useEffect(()=>{
    
    if(props.settingIntegrationdata)
    {
      setData(props.settingIntegrationdata?.data) 
      setFilterData(props.settingIntegrationdata?.data)
    }
  },[props.settingIntegrationdata])




  // deletedId variable contain the whole data so through the id ( in deletedId variable) we can delete the item by calling the deleteIntegration function 

  const DeleteIntegration = async(data) => {
    try
    {          
    setDeletedId(null)
    const response = await axios.delete(process.env.REACT_APP_BASE_API_CALL_URL+'integration/settings/'+ data.id,{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
    Accept: 'application/json'}})
    SettingIntegrationData()
    }
    catch(err)
    {
      alert("there is something wrong try again later")
    }
  }

  const close = () => {
    form.resetFields()
    setAuth_url(null)
    setOpenModel(false)
  }

 // it is function for active status 

  const activeState = async (data) => {    
    try
    {
      const config = { 
        headers: { 
        Authorization: 'Bearer ' + token.accessToken.accessToken,
        Accept: 'application/json'
    }};
    const response = await axios.put(process.env.REACT_APP_BASE_API_CALL_URL+'integration/settings/activate/'+ data.id, {}, config)
    setActiveID(false)
    SettingIntegrationData()
    }
    catch(err)
    {
      console.log(err)
    }
  }


  const DeactiveState = async (data) => {   

    try
    {
      const config = { 
        headers: { 
        Authorization: 'Bearer ' + token.accessToken.accessToken,
        Accept: 'application/json'
    }};
    const response = await axios.put(process.env.REACT_APP_BASE_API_CALL_URL+'integration/settings/deactivate/'+ data.id, {}, config)
    setActiveID(false)
    SettingIntegrationData()
    }
    catch(err)
    {
      console.log(err)
      alert('something went wrong try again later')
    }   
  }


 const HandleSearchItem = (e) => { 

  
      setSearchData(e.target.value)  
      if(e.target.value)
      {
        var search = e.target.value[0].toUpperCase() + e.target.value.substring(1)
      }    
      

      var cc = FilterData.filter((item) => {
        return item.integration_info.title.includes(search)        
      })
      
      
      setData(cc)
      
      setNoDataFound(false)
      if(cc.length===0 && e.target.value)
      {        
        setNoDataFound(true)
         setData(cc)
      }
      
      if(!e.target.value)
      {
        setData(FilterData)
      }

   }

  const [editValue, setEditValue] = useState()
  const [hhValue, sethhValue] = useState()

  useEffect(() => {
    projectSelectedData && Object.keys(projectSelectedData?.connection_prop_values).length > 0 ? setEditValue(projectSelectedData.connection_prop_values) : ''

    projectSelectedData !== null &&
      form.setFieldsValue({
        label: projectSelectedData.label,
        auth_type: projectSelectedData.auth_type,
        customer_sub_domain: editValue?.customer_sub_domain,
        test_date: moment(editValue?.test_date),
        test_enum: editValue?.test_enum,
        test_number: editValue?.test_number,
        test_obfuscated: editValue?.test_obfuscated,
        test_optional: editValue?.test_optional,
        test_time: moment(editValue?.test_time),
      }, [projectSelectedData]);
  })


  return (
    props.loading ? <Loader /> : 
    <React.Fragment>    
      <Modal visible={DeletedId ? true : false}
        onCancel={()=> setDeletedId(false)}
        footer={[
          <>
            <Button varient="danger" onClick={() => DeleteIntegration(DeletedId)}>delete</Button>
            <Button varient="secondary" onClick={() => setDeletedId(null)}>Cancel</Button>
          </>
        ]}>Do u want to delete      
      </Modal>
        {/* {console.log('Log ActiveID', activeID)} */}
      {activeID && <Modal
        visible={activeID ? true : false}
        onCancel={()=> setActiveID(null)}
        footer={[
          <>
          {                   
            activeID.is_active ? <Button varient="Primary" onClick={ () =>DeactiveState(activeID)}>Yes</Button> :
            <Button varient="Primary" onClick={ () => activeState(activeID)}>Yes</Button>
          }            
            <Button onClick={()=> setActiveID(null)} varient="secondary">Cancel</Button>
          </>
        ]}
      >Are you sure you want to {activeID.is_active ? ' deactivate this connection? ' : 'activate this connection? '}</Modal>
      }
      
      <div className="page-content">
        <MetaTags>
          <title>
            Projects List | Layerity
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          
          <Breadcrumbs  breadcrumbItem="Connected Apps" to="/projects-grid"  data={[{name:'Integration',path:"/projects-list"}, {name:'Connected Apps',path:"#"}]}/>
          <Input type="text" placeholder="Search by name" value={searchData} onChange={HandleSearchItem} style={{width:'60%',margin:'10px',padding:'10px'}} />
          
          <p style={{fontSize:'18px',color:'gray',fontWeight:'bold'}}>Total connected Apps - <span style={{fontWeight:'bold',color:'black'}}>{data && data.length}</span></p>
          
          <Row>
            {NoDataFound ? <Empty /> :   
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "100px" }}>
                          #
                        </th>
                        <th scope="col">Projects</th>
                        <th scope="col">Categary</th>
                        <th scope="col">Configuration</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {console.log('Log data', data)} */}
                      {data && map(data, (project, index) => {   
                                                           
                        let badgeColor;
                        let badgeStatusColor;
                        let splitArray = (project?.settings_status).split('_')                        
                        let catagary = (project?.integration_info?.type).split('_')
                        if(project.is_active)
                        {
                          badgeStatusColor = "success"
                        }
                        if(!project.is_active)
                        {
                          badgeStatusColor = "danger"
                        }
                        if(project?.settings_status === "SETUP_COMPLETED") 
                        {
                          badgeColor = "success"
                        }
                        else if(project?.settings_status === "SETUP_FAILED")
                        {
                          badgeColor = "danger"
                        }                        
                        else if (project?.settings_status === "SETUP_INITIATED")
                        {
                          badgeColor = "warning"
                        }
                        return (
                          <tr key={index}>
                          <td>
                            <img
                              src={project?.integration_info.icon_url}
                              alt=""
                              className="avatar-sm"
                            />
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">                                                              
                                {project?.integration_info?.title}                              
                            </h5>                    
                            <p className="text-muted mb-0">                              
                             <span data-toggle="tooltip" title={project.integration_info?.description}>{project.integration_info?.description.slice(0,50) +"..."}</span>                                                            
                            </p>
                          </td>
                          <td> {catagary.join(' ')}</td>
                          <td>
                            <Badge className={"bg-" + badgeColor}>
                              {splitArray.join(' ')}
                            </Badge>
                          </td>
                          <td>
                          <Badge style={{padding:'5px',fontSize:'14px',width:'120px',cursor:'pointer'}} className={"bg-" + badgeStatusColor } >
                           <span>{project.is_active ? '   Active  ' : 'Inactive'}</span>
                            </Badge>

                          {/* <span style={{ textAlign:'center' , padding:'2px 5px', width:'100%' , color:'white' , cursor:'pointer' , backgroundColor : `${project.is_active ? 'green' : 'red'}`}}>{project.is_active ? '   Active  ' : 'Inactive'}</span> */}
                            
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                href="#"
                                className="card-drop"
                                tag="i"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  href="#"
                                  onClick={() => handleProjectClick(project)}
                                >
                                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={() => setDeletedId(project)}
                                >
                                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                  Delete
                                </DropdownItem>
                                <DropdownItem>

                                <i className="mdi mdi-gesture-tap font-size-16 text-success me-1" />{" "}
                                <span type={project.is_active ? 'primary' : 'danger'} onClick={()=> setActiveID(project)}>{project.is_active ? 'Deactivate' : 'Activate'}</span>
                                </DropdownItem>

                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        )                                                               
                   })}
                    </tbody>
                  </Table>

                  {/* this is another part still don't care for that */}

          {openModel && (
            <Modal
              visible={openModel}
              onCancel={close}
              // style={{ marginTop: "100px" }}
              title={project.integration_info.title}
              footer={[
                <>
                  <Button onClick={close}>cancel</Button>
                  <Button
                    key="submit"
                    disabled={auth_url ? true : false}
                    type="primary"
                    htmlType="submit"
                    form="addform"
                  >
                    save
                  </Button>
                </>,
              ]}
            >
              {auth_url ? (
                <div>
                <p>
                  Please{" "}
                  <a href={auth_url} target="_blank" rel="noreferrer">
                    click
                  </a>{" "}
                  here.
                </p>
              </div>
              ) : (
              <Form
                form={form}
                id="addform"
                onFinish={handleSubmit}
                labelCol={{ span: 24 }}
              >
                <Row>
                  <Col xl={12}>
                    <Form.Item label="Label" name="label">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {project.integration_info.connection_properties &&
                  project.integration_info.connection_properties.map((item,index) => {
                    if (item?.value_type === "STRING") {
                      return (
                        <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }} >
                          <Input />
                        </Form.Item>
                      )
                    } else if (item.value_type === "NUMBER") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }}>
                            <InputNumber />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "DATE") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }}>
                            <DatePicker />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "TIME") {
                      return (
                        <>
                        <Form.Item
                          label={item.label}
                          name={item.name}
                          initialvalues={"00:00:00", "HH:mm:ss"}
                        >
                          <TimePicker
                            onChange={(e) => {
                              // handleChange();
                              handleProperties(e, item.name);
                            }}
                          />
                        </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "ENUM") {
                      return (
                        <>
                          <Form.Item label={item.label} name={item.name} required>
                            <Select
                              allowClear
                              placeholder={item.name}
                              optionFilterProp="children"
                              onChange={(e) => {
                                // handleChange();
                                handleProperties(e, item.name);
                              }}
                            >
                              {item.allowed_values &&
                                item.allowed_values.map((val, key) => (
                                  <Option key={key} value={key}>
                                    {val}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleProperties(e.target.value, item.name);
                          }}>
                          <Input required={true}/>
                        </Form.Item>
                        </>
                      )
                    }
                  })}
                {project && project.integration_info.auth_settings && (
                  <Row>
                    <Col xl={12}>
                      <Form.Item label="Select Auth Type" name="auth_type" initialvalues="OAuth-v2">
                        <Select
                          style={{
                            width: "100%",
                            padding: "5px",
                            borderColor: "gray",
                            fontSize: "16px",
                          }}
                        onChange={(e) => handleAuthTypeChange(e)}
                        >
                          {project &&
                            project.integration_info.auth_settings.map((item, index) => (
                              <Option
                                value={item.auth_type_display_name}
                                key={index}
                              >
                                {item.auth_type_display_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {selectedAuthType &&
                  selectedAuthType[0].auth_property_definitions.map(item => {
                    if (item?.value_type === "STRING") {
                      return (
                        <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                          <Input />
                        </Form.Item>
                      )
                    } else if (item.value_type === "NUMBER") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                            <InputNumber />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "DATE") {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                            <DatePicker />
                          </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "TIME") {
                      return (
                        <>
                        <Form.Item
                          label={item.label}
                          name={item.name}
                          initialvalues={"00:00:00", "HH:mm:ss"}
                        >
                          <TimePicker
                            onChange={(e) => {
                              // handleChange();
                              handleAuthProperties(e, item.name);
                            }}
                          />
                        </Form.Item>
                        </>
                      )
                    } else if (item.value_type === "ENUM") {
                      return (
                        <>
                          <Form.Item label={item.label} name={item.name} required>
                            <Select
                              allowClear
                              // placeholder={item.name}
                              optionFilterProp="children"
                              onChange={(e) => {
                                // handleChange();
                                handleAuthProperties(e, item.name);
                              }}
                            >
                              {item.allowed_values &&
                                item.allowed_values.map((val, key) => (
                                  <Option key={key} value={key}>
                                    {val}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <Form.Item label={item.label} required={!item.optional} name={item.name}  onChange={(e) => {
                            // handleChange();
                            handleAuthProperties(e.target.value, item.name);
                          }}>
                          <Input />
                        </Form.Item>
                        </>
                      )
                    }
                  })}
              </Form>
              )}
            </Modal>
          )}
                </div>
              </div>
            </Col>
          }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};




const mapStateToProps = (state) => ({
  settingIntegrationdata : state.SettingIntegrationReducer.settingIntegration,
  loading : state.SettingIntegrationReducer.loading
})

const mapDispatchToProps = ({
  GET_SETTING_INTEGRATION_REQUEST:GET_SETTING_INTEGRATION_REQUEST
})

export default compose(withRouter , connect(mapStateToProps,mapDispatchToProps))(ProjectsList)
