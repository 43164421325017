import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import './index.scss';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

//import Charts
import StackedColumnChart from "./StackedColumnChart";
import StackedColumnChart1 from "./StackedColumnChart-v"
//import action

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

//i18n
import { withTranslation } from "react-i18next";

// tooken



//redux
import { useSelector, useDispatch } from "react-redux";

// user actions


import { getWelcomeData } from "store/dash/actions";
import {connect} from 'react-redux';
import Loader from "utils/Loader";
import { compose } from "redux";


const Dashboard = props => {
  const dispatch = useDispatch()
  const [token,setToken] = useState(JSON.parse(localStorage.getItem('okta-token-storage')))
  const [modal, setmodal] = useState(false);
  const [tilesData,setTilesData] = useState([])
  const [chartData,setChartdata] = useState([])

  

  useEffect(() => {    
    props.WelcomeDataRequest()
  },[])


  useEffect(()=>{
    if(props.DashData?.WelcomeApiData)
    {
    const chartRes = Object.entries(props.DashData?.WelcomeApiData?.charts)
    const tilesRes = Object.entries(props.DashData?.WelcomeApiData?.tiles)  
    setTilesData(tilesRes)
    setChartdata(chartRes)
    }
  },[props.DashData?.WelcomeApiData])



  const user =  useSelector((state) => state.UserInformation)
  const history = useHistory()
  
  useEffect(() => {

    async function getData()
    {      
      const response = await axios.get(process.env.REACT_APP_BASE_API_USERINFO_URL+"userinfo/",{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
      Accept: 'application/json'}})          
      dispatch({type:"USER_INFO",paylaod:response.data})
    }
    getData()
    if(!localStorage.getItem('okta-token-storage'))
    {
      history.push('/login')
    }
  },[])
  

  return (
    props.DashData.loading ? <Loader /> : 
    (
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Layerity</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <h5 style={{padding:'10px'}}>Welcome Back, {user?.user?.name}</h5>
          <Row>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {tilesData?.map((report, key) => {
                  const title = report[0].split('_')
                  const uppertop=title?.map((data)=> data.charAt(0).toUpperCase()+data.slice(1))                  
                  // console.log(dd)
                  //console.log(title)
                  var tempKeys = Object.keys(report[1])
                  var tempValues = Object.values(report[1])
                  var OgKeys = tempKeys.map((x) => x.split('_'))

                  const newObj1 =  OgKeys[0].map((data)=> data.charAt(0).toUpperCase()+data.slice(1))
                  const newObj2 =  OgKeys[1].map((data)=> data.charAt(0).toUpperCase()+data.slice(1))

                  const NewTempKeys = []
                  
                  NewTempKeys.push(newObj1.join(' '),newObj2.join(' '))

                  return (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid dashboard-top-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              <span style={{"color":"#ff1493","fontweight":"900"}}>{uppertop.join(' ')}</span>
                            </p>
                            <h4 className="mb-0" style={{fontSize:'10px'}}> <span style={{"color":"#ff1493","fontweight":"900"}}>{` ${tempValues[1]}`}</span> {`${NewTempKeys[1]}`}</h4>
                          </div>
                          <div className="avatar-sm ">
                            <span className="avatar-title" style={{backgroundColor:'transparent'}}>
                            <h4 className="mb-0" >{tempValues[0]}</h4>
                            </span> </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )})}
              </Row>
              <Row>
                <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Most Used Apps</h4>                   
                  </div>
                  {/* <div className="clearfix"></div> */}
                 
                  {chartData.length > 0 &&
                    <StackedColumnChart
                      periodData={chartData[0][1].data.data}
                    />
                  }
                </CardBody>
              </Card>
              </Col>

              <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Cost Breakedown</h4>                    
                  </div>
                  {/* <div className="clearfix"></div> */}
                  {chartData.length > 0 &&
                    <StackedColumnChart1
                      periodData={chartData[1][1].data.data}
                    />
                  }
                </CardBody>
              </Card>
              </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >        
      </Modal>
    </React.Fragment>
    )
   
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};


const mapstateToProps = (state) => ({

  DashData : state.Dash
})

const mapDispatchToProps = ({

  WelcomeDataRequest : getWelcomeData 

})


export default compose(withTranslation(),connect(mapstateToProps,mapDispatchToProps))(Dashboard);