import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// import Chart 
import StackedColumnChart1 from "./StackChart";
import PieChart from "./PieChart";

import axios from "axios";
import ApplicationList from "./ApplicationList";
import {compose} from 'redux';
import { connect } from "react-redux";
import { SPEND_CHART_DATA_REQUEST,SPEND_APPLICATION_DATA_REQUEST } from "store/spend/action";
import Loader from "utils/Loader";


const EcommerceOrders = props => {

  const [token,setToken] = useState(JSON.parse(localStorage.getItem('okta-token-storage')))

  const [data,setData] = useState([])

  const [pieChartData,setPieChartData] = useState(null)
  const [stackChartData,setStackChartData] = useState(null)
  const [applicationData,setApplicationData] = useState([])

  useEffect(()=>{
    props.SPEND_APPLICATION_DATA_REQUEST()
    props.SPEND_CHART_DATA_REQUEST()
  },[])


  useEffect(()=>{ 
    if(props.charts)
    {
      setData(props.charts)     
      setPieChartData(props.charts[0])
      setStackChartData(props.charts[1])
    }

    if(props.application)
    {
      setApplicationData(props.application.data)
    }
  },[props.charts,props.application])

  const PiechartName = pieChartData?.name
  const PiedataLabel = pieChartData?.data?.data?.labels
  const PiechartData = pieChartData?.data?.data?.datasets[0]?.data
  const Pielabels = pieChartData?.data?.data?.labels



  return (
    props.loading ? <Loader /> :
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Orders | Layerity</title>
        </MetaTags>
          <Row>
        <Breadcrumbs  breadcrumbItem="Spend" to="#"  data={[{name:'Analyze',path:"#"}, {name:'Spend',path:"#"}]}/>
        <Col xl={6} md={6} sm={12}>
          
          {pieChartData &&
          
          <PieChart labels={pieChartData?.data?.data?.labels}  name={pieChartData?.name}  data={pieChartData?.data?.data?.datasets[0]?.data}/>          
          }
        </Col>
        {
          stackChartData && 
          <Col xl={6} md={6} sm={12}>
            <StackedColumnChart1 periodData={stackChartData?.data?.data} name={stackChartData?.name}/>        
        </Col>
        }
        </Row>             
        <Row>
            <Col xs="12">
              <Card className="tab">
                <CardBody>
                         <ApplicationList />        
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  charts : state.SpendReducer.charts,
  loading : state.SpendReducer.loading,
  application : state.SpendReducer.application
})

const mapDispatchToProps = ({  
  SPEND_CHART_DATA_REQUEST : SPEND_CHART_DATA_REQUEST,
  SPEND_APPLICATION_DATA_REQUEST : SPEND_APPLICATION_DATA_REQUEST
})

export default compose(withRouter , connect(mapStateToProps,mapDispatchToProps))(EcommerceOrders);

