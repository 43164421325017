import { FETCH_ALL_CATALOG_FAILED } from "./actionType";
import { FETCH_ALL_CATALOG_SUCCESS } from "./actionType";


const initialState = {
    ALLCATALOG : [],
    loading:true
}

const CatalogInterationReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case FETCH_ALL_CATALOG_SUCCESS:
            return {
                ...state,
                ALLCATALOG : action.data,
                loading:false
            }
        case FETCH_ALL_CATALOG_FAILED:
            return {
                ...state,
                ALLCATALOG : [],
                loading:true
            }
        default:
            return state;
    }
}


export default CatalogInterationReducer;