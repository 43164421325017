import { WELCOMEDATA_REQUEST } from "./actionType";
import { WELCOMEDATA_REQUEST_SUCCESS,WELCOMEDATA_REQUEST_FAILED } from "./actionType";

export const getWelcomeData = () => ({
    type:WELCOMEDATA_REQUEST
})


export const getWelcomeData_Success = (data) => ({
    type:WELCOMEDATA_REQUEST_SUCCESS,
    data:data
})


export const getWelcomeData_Failed = (data) => ({
    type:WELCOMEDATA_REQUEST_FAILED,
    data :data
})

