import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart1 = ({ periodData,name }) => {  
  
  let dt = name?.split('_')
  
  var options = {

    series: [{
    data: periodData.datasets[0].data
  }],
    chart: {
    type: 'bar',
    height: 380
  },
  title: {
    text: dt?.join(' '),
    align: 'left',
    margin: 5,
    offsetX: 0,
    offsetY: 0,
    floating: false,
    style: {
      fontSize:  '14px',
      fontWeight:  'bold',
      fontFamily:  undefined,
      color: '#ffffff'
    },
},
  plotOptions: {
    bar: {
      barHeight: '60%',
      distributed: true,
      horizontal: true,
      dataLabels: {
        position: 'bottom'
      },
      toolbar:{
        show:true
      }
    }
  },
  colors: periodData.datasets[0].backgroundColor,
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#fff'],
      fontSize: 8
    },
    formatter: function (val, opt) {
      return val
    },
    offsetX: 0,
    offsetY: 1,
  },
  legend: {
    show: false
  },
  xaxis: {
    categories: periodData.labels,
  },
  yaxis: {
    labels: {
      show: true
    }
  },
  tooltip: {
    theme: 'dark',
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function () {
          return ''
        }
      }
    }
  }
  };
  return (
    <React.Fragment>
      
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height="480"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

StackedColumnChart1.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart1;