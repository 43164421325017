import { FETCH_ALL_CATALOG } from "./actionType";
import { FETCH_ALL_CATALOG_FAILED } from "./actionType";
import { FETCH_ALL_CATALOG_SUCCESS } from "./actionType";



export const ALL_CATALOG_REQUEST = () => ({
    type:FETCH_ALL_CATALOG
})

export const ALL_CATALOG_REQUEST_SUCCESS = (data) => ({
    type : FETCH_ALL_CATALOG_SUCCESS,
    data : data
})

export const ALL_CATALOG_REQUEST_FAILED = (data) => ({
    type : FETCH_ALL_CATALOG_FAILED,
    data : data
})