import { takeLatest,put,call } from "redux-saga/effects";
import { SPEND_CHART_DATA } from "./actionType";
import { SPEND_CHART_DATA_FAILED_REQUEST } from "./action";
import { SPEND_CHART_DATA_SUCCESS_REQUEST } from "./action";
import { SPEND_APPLICATION_DATA } from "./actionType";
import { SPEND_APPLICATION_DATA_SUCCESS_REQUEST } from "./action";
import { SPEND_APPLICATION_DATA_FAILED_REQUEST } from './action';

import axios from "axios";


const SpendChartDataApi = async () => {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'))

    const response = await axios.get(process.env.REACT_APP_BASE_API_CALL_URL+'spend/home/charts/',{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
    Accept: 'application/json'}})
    return response.data
}

const getApplicationDataApi = async () => {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'))
     const response = await axios.get(process.env.REACT_APP_BASE_API_CALL_URL+'spend/home/applications/',{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
    Accept: 'application/json'}})  
    return response
}


function* getApplicationData()
{
    try
    {
        var response = yield call(getApplicationDataApi)
        yield put(SPEND_APPLICATION_DATA_SUCCESS_REQUEST(response))
    }
    catch(err)
    {
        console.log(err)
        yield put(SPEND_APPLICATION_DATA_FAILED_REQUEST(err))
    }
}


function* getSpendChartData()
{
    try
    {
        var response = yield call(SpendChartDataApi)
        yield put(SPEND_CHART_DATA_SUCCESS_REQUEST(response))
    }
    catch(err)
    {
        yield put(SPEND_CHART_DATA_FAILED_REQUEST(err))
    }
}


function* SpendSaga()
{
    yield takeLatest(SPEND_CHART_DATA,getSpendChartData)
    yield takeLatest(SPEND_APPLICATION_DATA,getApplicationData)
}

export default SpendSaga;