import { SPEND_CHART_DATA } from "./actionType";
import { SPEND_CHART_DATA_SUCCESS } from "./actionType";
import { SPEND_CHART_DATA_FAILED } from "./actionType";
import {SPEND_APPLICATION_DATA} from './actionType'
import { SPEND_APPLICATION_DATA_SUCCESS } from "./actionType";
import { SPEND_APPLICATION_DATA_FAILED } from "./actionType";

export const SPEND_CHART_DATA_REQUEST = () => {
    return({
        type : SPEND_CHART_DATA
    })
}

export const SPEND_CHART_DATA_SUCCESS_REQUEST = (data) => {
    return({
        type : SPEND_CHART_DATA_SUCCESS,
        data : data

    })
}

export const SPEND_CHART_DATA_FAILED_REQUEST = (data) => {
    return ({
        type : SPEND_CHART_DATA_FAILED,
        data : data
    })
}

export const SPEND_APPLICATION_DATA_REQUEST = () => {
    return({
        type : SPEND_APPLICATION_DATA
    })
}

export const SPEND_APPLICATION_DATA_SUCCESS_REQUEST = (data) => {
    return({
        type : SPEND_APPLICATION_DATA_SUCCESS,
        data : data

    })
}

export const SPEND_APPLICATION_DATA_FAILED_REQUEST = (data) => {
    return ({
        type : SPEND_APPLICATION_DATA_FAILED,
        data : data
    })
}
