import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import '../Dashboard/index.scss';
import axios from 'axios';
import '../Dashboard/index.scss'
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Row,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Empty } from 'antd';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {ALL_CATALOG_REQUEST} from '../../store/integration/action';
import {connect} from 'react-redux';
import { compose } from "redux";
import Loader from "utils/Loader";
import { Input } from "antd";

const ProjectsGrid = props => {
  
  const [showActive,setShowActive] = useState('')
  const [TypeRelateData,setTypeRelateData] = useState([])
  const [data,setData] = useState([])
  const [Type,setType] = useState(null)

  const [noDataFound,setNoDataFound] = useState(false)

  const [searchData,setSearchData] = useState('')

  useEffect(()=>{
    props.CATALOG_REQUEST()
    setType("Show_ALL_DATA_Key")
  },[])

  useEffect(()=>{
    if(props.allCalalog)
    {
      setData(props.allCalalog?.data)
    }
  },[props.allCalalog])


 
  const [typesData,setTypesData] = useState([])

  useEffect(()=>{

    function SetType()
    {
      let obj = {}
      data?.forEach((item) => {
          if(!obj[item.type]) { obj[item.type] = [] }
          obj[item.type].push(item)
     })
      const keysData = Object.keys(obj)
      const FilterKeys=[]
      keysData.forEach((item)=>{
          let newData = item.split('_')
           FilterKeys.push([(newData.join(' ')),item])
      })
       setTypesData(FilterKeys)
    }
    if(data)
    {
    SetType()
    showAllTypeData()
    }    
  },[data])


  function showAllTypeData()
  {     
        const arrayData= Object.values(data)
        const realArrayData = arrayData.map((item)=> item)
        setTypeRelateData(realArrayData)         
  }


  function ShowTypeData(keys)
  {

    setType(keys)
    if(keys==="Show_ALL_DATA_Key")
    {
      showAllTypeData()   
    }
    else
    {
      // setShowActive('active')
    const selectedData = data?.filter((item,index)=>item.type==keys)   
    setTypeRelateData(selectedData)
    // setShowActive(true)
    }
  }


  const handleSearchData = (dataValue) => {

    var search = dataValue.toUpperCase()[0]+dataValue.substring(1);   
    
    if(Type=="Show_ALL_DATA_Key")
    {
      const dd = data?.filter((item,index) => item.title.includes(search))
      setTypeRelateData(dd) 

      setNoDataFound(false)
      if(dd.length===0 && dataValue)
      {
        setNoDataFound(true)
      }

      if(!dataValue)
      {
        setTypeRelateData(data)
      }
    }

    else if(Type)
    {
      const selectTypeData = data?.filter((item,index) => item.type.includes(Type))
      var search = dataValue.toUpperCase()[0]+dataValue.substring(1);
      const dd = selectTypeData?.filter((item,index) => item.title.includes(search))
      setTypeRelateData(dd)   

      setNoDataFound(false)

      if(dd.length===0 && dataValue)
      {
        setNoDataFound(true)
      }
      
      if(!dataValue)
      {
        setTypeRelateData(selectTypeData)
      }
    }    

  }

  const HandleSearchItem = (e) => {
    setSearchData(e.target.value)
    handleSearchData(e.target.value)
  }


  return (
    props.loading ? <Loader /> : (
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Projects Grid | Layerity
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Integrations" breadcrumbItem="catalog" /> */}

          <Breadcrumbs  breadcrumbItem="Catalog" to="/projects-grid"  data={[{name:'Integrations',path:"/projects-grid"}, {name:'Catalog',path:"#"}]}/>
          {/* <Breadcrumbs title="Integrations" breadcrumbItem="Catalog" to="/projects-grid"/> */}

          <Input type="text" placeholder="Search Items" value={searchData} onChange={HandleSearchItem} style={{width:'60%',margin:'10px',padding:'10px'}} />
          <hr />
          <Row>
            <Col xl={2} className='new-row'>
             <p className={showActive === "" ? 'button-design active' : 'button-design'} onClick={() => {ShowTypeData('Show_ALL_DATA_Key'); setShowActive('')}}>All Items</p>
              {
              typesData?.map((item,index)=>{
                return(
                  <p className={showActive === index ? 'button-design active' : 'button-design' }  key={index} style={{fontSize:'16px'}} onClick={() => {ShowTypeData(item[1]); setShowActive(index)}}>{item[0]}</p>
                )
              })
            }
            </Col>
            <Col xl={10}  className="grid">

              {noDataFound  ? <Empty /> : 
              <Row style={{display:'flex-start'}}>
             {
               TypeRelateData?.map((item,index)=>{                 
                 return(                   
                    <Col md={2} key={index}>
                   <Card><Link style={{color:'#000'}}to={{ pathname: "/catalog-integration", state: item}} type="link">
                        <CardImg top src={item?.icon_url} alt={item?.title} />
                        <CardBody>
                        <CardTitle>{item?.title}</CardTitle>
                        </CardBody>
                        </Link>
                    </Card>
                    </Col>                   
                 )
               })
             }
             </Row>
             }
            </Col>
          </Row>        
        </Container>
      </div>
    </React.Fragment>
    )
   
  );
};

const mapstateToProps = (state) => ({
  allCalalog : state.CatalogInterationReducer.ALLCATALOG,
  loading : state.CatalogInterationReducer.loading
})


const mapDispatchToprops = ({
  CATALOG_REQUEST : ALL_CATALOG_REQUEST
})


export default compose(withRouter, connect(mapstateToProps,mapDispatchToprops)) (ProjectsGrid)





