import { GET_SETTING_INTEGRATION } from "./actionType";
import { GET_SETTING_INTEGRATION_SUCCESS } from "./actionType";
import { GET_SETTING_INTEGRATION_FAILED } from "./actionType";


export const GET_SETTING_INTEGRATION_REQUEST = () => {
    console.log("this is action")
return({
    type : GET_SETTING_INTEGRATION
})
}


export const GET_SETTING_INTEGRATION_SUCCESS_REQUEST = (data) => {
    return ({
        type : GET_SETTING_INTEGRATION_SUCCESS,
        data : data
    })
}


export const GET_SETTING_INTEGRATION_FAILED_REQUEST = (data) => {
    return({
        type : GET_SETTING_INTEGRATION_FAILED,
        data : data
    })
}

