import { takeLatest,call,put } from "redux-saga/effects";
import { SPEND_OVERVIEW } from "./actionType";
import { SPEND_OVERVIEW_SUCCESS_REQUEST } from "./actions";
import { SPEND_OVERVIEW_FAILED_REQUEST } from "./actions";
import axios from "axios";



const SpendOverViewDataApi = async (id) => {
    const token = JSON.parse(localStorage.getItem("okta-token-storage"))
    const response = await axios.get(`${process.env.REACT_APP_BASE_API_CALL_URL}`+'spend/app/'+id,{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
    Accept: 'application/json'}})           
    return response
}

function* getSpendOverViewData({id})
{
    try
    {
        const response = yield call(SpendOverViewDataApi,id)
        yield put(SPEND_OVERVIEW_SUCCESS_REQUEST(response.data))
    }
    catch(err)
    {
        console.log(err)
        yield put(SPEND_OVERVIEW_FAILED_REQUEST(err))
    }
}


function* SpendOverViewSaga()
{
   yield takeLatest(SPEND_OVERVIEW,getSpendOverViewData)

}


export default SpendOverViewSaga;