import { SPEND_OVERVIEW_SUCCESS,SPEND_OVERVIEW_FAILED } from "./actionType";

const initialState = {
    data:null,
    loading:true
}
const SpendOverViewReducer = (state=initialState,action) => {

    switch(action.type)
    {
        case SPEND_OVERVIEW_SUCCESS:
            return {
                ...state,
                data : action.data,
                loading:false                
            }
        case SPEND_OVERVIEW_FAILED:
            console.log("this is errro")
            return {
                ...state,
                data:null,
                loading:true
            }
        default:
            return state
    }
}

export default SpendOverViewReducer;