import React from "react"
import ReactApexChart from "react-apexcharts"

const SpendChart = (props) => {


  const series = [
    {
      name:props.label,
      data: props?.data,
    },
  ]
  
  const options = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    title: {
      text: props?.label,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '14px',
        fontWeight:  'bold',
        fontFamily:  undefined,
        color:  '#263238'
      },
  },
  subtitle: {
        text: props?.description,
        align: 'left',
        margin: 90,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'normal',
          fontFamily:  undefined,
          color:  'gray'
        },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },      
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f"],
    grid: {
      borderColor: props.backgroundColor,
    },
    colors: props.borderColor,
    xaxis: {
      categories: props?.labels,
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height="350" />
  )
}

export default SpendChart;
