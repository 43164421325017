import { takeEvery,put,call } from "redux-saga/effects";
import { FETCH_ALL_CATALOG } from "./actionType";
import {ALL_CATALOG_REQUEST_SUCCESS} from './action'
import { ALL_CATALOG_REQUEST_FAILED } from "./action";


import axios from 'axios'



const getCatalogDataApi = async () => {

    const token = JSON.parse(localStorage.getItem('okta-token-storage'))
    const response = await axios.get(process.env.REACT_APP_BASE_API_CALL_URL+'integrations/',{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
    Accept: 'application/json'}})   
    return response
}

function*  getCatalogData()
{
    try
    {
        const response = yield call(getCatalogDataApi)
        yield put(ALL_CATALOG_REQUEST_SUCCESS(response))
    }
    catch(err)
    {
        yield put(ALL_CATALOG_REQUEST_FAILED(err))
    }
}

function* AllCatalogIntegrationSaga()
{
    yield takeEvery(FETCH_ALL_CATALOG,getCatalogData)
}

export default AllCatalogIntegrationSaga;


