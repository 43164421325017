import React, { useEffect, useRef, useState } from 'react'
import { Row, Table } from 'reactstrap'
import axios from 'axios'
import { Link ,useHistory} from 'react-router-dom'
import Graphs from './Graphs'
import { Input } from 'antd'
import { Empty } from 'antd';




import './Pagination.css'


import { Button } from 'antd'


export default function ApplicationList() {

    const [token,setToken] = useState(JSON.parse(localStorage.getItem('okta-token-storage')))
    const [showModel,setShowModel] = useState(false)    

    const [BootColumnData,setBootColumnData] = useState([])
    const [BootRowData,setBootRowData] = useState([])

    const [noDataFound,setNoDataFound] = useState(false)

    const [applicationHeader,setApplicationHeader] = useState([])
    const [GraphsData,setGraphsData] = useState({data:'',title:''})
    const [RowData,setRowData] = useState([])

    const [currentPage, setCurrentPage] = React.useState(1);
    const [maxPages,setMaxPage] = useState(2)


    const [FilterData,setFilterData] = useState([])


    const defaultImageWay = useRef(null)
      let items = [];


    const history = useHistory()

    useEffect(()=>{

        async function getData()
        {
          const response = await axios.get(process.env.REACT_APP_BASE_API_CALL_URL+`spend/home/applications?page=${currentPage}&size=${10}`,{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,
          Accept: 'application/json'}})  
          setMaxPage(response?.data?.pagination?.total_pages)
          //setPage({...page,totalPage:response?.data?.pagination?.total_pages})
          setApplicationHeader(response?.data?.header)
          setRowData(response.data?.data)
          setFilterData(response.data?.data)
          //setApplicationData(merged)                    
        }
        getData()
      },[currentPage])
    

  






      const SpendOverPage = (data) => {      
          //console.log(data)          
      }


      let leftSide = currentPage - 2;
      if(leftSide <= 0 ) leftSide=1;
      let rightSide = currentPage + 2;
      if(rightSide>maxPages) rightSide = maxPages;
      for (let number = leftSide ; number <= rightSide; number++) {
        items.push(
          <div key={number} className={(number === currentPage ? 'round-effect active-pagination' : 'round-effect')} onClick={()=>{ setCurrentPage(number)}}>
            {number}
          </div>,
        );
      }

  const nextPage = () => {
        if(currentPage<maxPages){
          setCurrentPage(currentPage+1)
        }
    }
      
  const prevPage = () => {
        if(currentPage>1){
          setCurrentPage(currentPage-1)
        }
  }



  const handleSearch = (e) => {
    // setSearch(e.target.value)

    var search = e.target.value.toUpperCase()[0]+e.target.value.substring(1);
    const dd = RowData.filter((item) => {
      return (
        item[1].includes(search)
      )
    })

    setFilterData(dd)

    if(dd.length===0)
    {
      setNoDataFound(true)
    }
    else
    {
      setNoDataFound(false)
    }

    if(!e.target.value && dd.length===0)
    {
      setFilterData(RowData)
      setNoDataFound(false)
    }

  }


 const GraphFormatter = (cell,row) => 
 {   
   return (

     <button onClick={() => alert(row.spend_trend)}>Click me</button>
   )
 }





  

  // const handleSearch = (e) => 
  // {    

  //   var search = e.target.value.toUpperCase()[0]+e.target.value.substring(1);
  //   const dd = RowData.filter((item)=>item[1].includes(search))

  //   console.log(dd.length)

  //   console.log(FilterData)

  //   if(dd.length===0)
  //   {      
  //     setRowData(FilterData)      
  //   }

  //   setRowData(dd)

  //   if(!e.target.value)
  //   {
  //     setRowData(FilterData)
  //   }

  // }

  //BootColumnData
  return (
    <>  
    

    {showModel  && <Graphs visible={showModel} onChange={setShowModel} data={GraphsData}/>}

    <Input type="text" placeholder="Search Items" onChange={handleSearch} style={{width:'60%',margin:'10px',padding:'10px'}} />
    <hr />
  

    <Table hover className='tabl'>
        <thead>
          <tr>
              {
                  applicationHeader?.map((column,index)=>{
                                          
                      let data = column?.name.split('_')
                      return(                          
                          <th key={index}>{data.join(' ')}</th>                          
                      )                    
                  })
              }
              <th>Actions</th>
          </tr>
        </thead>
        <tbody>
            {             
            RowData &&      
                    FilterData?.map((parent,index)=>{                                                                                          
                    return (                        
                        <tr key={index} onClick={() => SpendOverPage(parent)}>                           
                            <td><Link style={{color:'#000'}}to={{ pathname: "/spend-overview",state: parent[0]}}type="link">{parent[0]}</Link></td>
                            <td><img  style={{width:100}} src={`https://api.layerity.com${parent[2]}` } ref={defaultImageWay} id={parent[2]} /></td>
                            <td>{parent[1]}</td>
                            <td>{parent[3]}</td>
                            <td>{parent[4]}</td>
                            <td>{parent[5]}</td>
                            <td><button style={{background:"blue",color:'white',border:'none',borderRadius:'10px',padding:'5px'}} onClick={() => (setGraphsData({data:parent[6],title:`Spend Trend - ${parent[1]}` }),setShowModel(true))}>Trends</button></td> 
                            <td><button style={{background:"blue",color:'white',border:'none',borderRadius:'10px',padding:'5px'}} onClick={() => (setGraphsData({data:parent[7],title:`User Trend - ${parent[1]}`}),setShowModel(true))}>Trends</button></td> 
                            <td><Link style={{color:'#000'}}to={{ pathname: "/spend-overview",state: parent[0]}}type="link"><button style={{background:"blue",color:'white',border:'none',borderRadius:'10px',padding:'5px'}}>View Details</button></Link></td>                                     
                        </tr>                        
                    )
                })
            }              
        </tbody>      
      </Table>
      {noDataFound && <Empty />}
      <div className='pagination' style={{displayf:'flex',justifyContent:'flex-end'}}>
                <div className="flex-container">                               
                    <div className="paginate-ctn">
                      <div className="round-effect" onClick={prevPage}> &lsaquo; </div>
                      {items}
                      <div className="round-effect" onClick={nextPage}> &rsaquo; </div>
                    </div>
                  </div>
            </div>
    </>
  )
}