import axios from 'axios'
import { call, put, takeEvery } from "redux-saga/effects";

import {getWelcomeData_Success,getWelcomeData_Failed } from "./actions";
import {WELCOMEDATA_REQUEST} from './actionType'


const WelcomeDataApi = async () => {
          const token = JSON.parse(localStorage.getItem('okta-token-storage'))
           const response = await axios.get(process.env.REACT_APP_BASE_API_CALL_URL +'welcome/',{ headers: { Authorization: 'Bearer ' + token.accessToken.accessToken,           
           Accept: 'application/json'}})                                 
           return response            
    
    }


function* fetchWelcomeData() {
    try {              
      const response = yield call(WelcomeDataApi)          
      yield put(getWelcomeData_Success(response.data))
    } catch (error) {       
      yield put(getWelcomeData_Failed(error))
    }
}


function* DashSaga() {
    yield takeEvery(WELCOMEDATA_REQUEST, fetchWelcomeData)    
  }
  

export default DashSaga;


  

