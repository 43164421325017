import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user:null
}


export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      SetUserInfo: (state,action) => {
        console.log('the actions data is ',action.payload)
        state.user = action.payload
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { SetUserInfo } = UserSlice.actions
  
  export const UserDetails = (state) => state.User.user;

  export default UserSlice.reducer;