import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

//import SecureRoutePath from "./routes/SecureRoutePath"

// Import all middleware
import Authmiddleware from "./routes/route"
import SecureRoutePath from './routes/SecureRoutePath';

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"


import { LoginCallback, SecureRoute, Security , useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import {useSelector} from 'react-redux';





// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }
// init firebase backend
// initFirebaseBackend(firebaseConfig)


const App = props => {


  const oktaAuth = new OktaAuth({
    issuer: 'https://login.layerity.com/oauth2/default',
    clientId: '0oa5qn8s19Q2zCSZl5d6',
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "email", "profile"],
    pkce: true,
  });

 
   //const dispatch = useDispatch();
   
  
   const history = useHistory();
  
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const onAuthRequired = function() {
    //console.log("this is onauth required tab")
    history.push('/login')
  }

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }


  const Layout = getLayout()
  return (
    <React.Fragment>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <SecureRoutePath
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Security>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
