import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart1 = ({ periodData }) => {
  // console.log('the period data is ',periodData)
  var options = {
    series: [{
    data: periodData.datasets[0].data
  }],
    chart: {
    type: 'bar',
    height: 380
  },
  plotOptions: {
    bar: {
      barHeight: '60%',
      distributed: true,
      horizontal: true,
      dataLabels: {
        position: 'bottom'
      },
    }
  },
  colors: periodData.datasets[0].backgroundColor,
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#fff'],
      fontSize: 8
    },
    formatter: function (val, opt) {
      return val
    },
    offsetX: 0,
    offsetY: 1,
  },
  legend: {
    show: false
  },
  xaxis: {
    categories: periodData.labels,
  },
  yaxis: {
    labels: {
      show: true
    }
  },
  tooltip: {
    theme: 'dark',
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function () {
          return ''
        }
      }
    }
  }
  };
  return (
    <React.Fragment>
      
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height="360"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

StackedColumnChart1.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart1;