import { GET_SETTING_INTEGRATION_SUCCESS } from "./actionType";
import { GET_SETTING_INTEGRATION_FAILED } from "./actionType";



const initialState = {

    settingIntegration:[],
    loading:true
}

const SettingIntegrationReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case GET_SETTING_INTEGRATION_SUCCESS:
            return {
                ...state,
                settingIntegration:action.data,
                loading:false
            }
        case GET_SETTING_INTEGRATION_FAILED:
            return {
                ...state,
                settingIntegration:[],
                loading:true
            }
        default:
            return state;
    }
}

export default SettingIntegrationReducer;

