import { notification } from "antd";


const Notify = {
    success : (data) => {
        return notification.success({placement:'topRight',duration: 8,...data})
    },
    error : (data) => {
        return notification.error({placement:'topRight',duration:3,...data})
    }
}

export {Notify};