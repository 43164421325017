import React from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = (props) => {
    
    let dt = props?.name?.split('_')


    const series = props?.data
  const options = {
    labels: props?.labels,
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    plotOptions: {
        toolbar:{
            show:true
          }
    },
    title: {
        text: dt?.join(' '),
        align: 'left',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#9699a2'
        },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function (t) {
            return `${t+' $'}`
          }
        }
      }
    }
  }

  return (
    <ReactApexChart options={options} series={series} type="pie" height="380" />
  )
}

export default PieChart
