import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = ({ periodData }) => {
  // console.log('v stack')
  var options = {
    series: [{
    data: periodData.datasets[0].data
  }],
    chart: {
      type: 'bar',
      height: 360,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        columnWidth: '55%',
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'center'
        },
        borderRadius: 2,
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'center',
      style: {
        colors: ['#000'],
        fontSize: 8
      },
      formatter: function (val, opt) {
        return val
      },
      offsetX: -8,
    },
    colors: periodData.datasets[0].backgroundColor,
    yaxis: {
      labels: {
        show: true
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: periodData.labels,
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height="360"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any
}
export default StackedColumnChart;