import { SPEND_APPLICATION_DATA_SUCCESS, SPEND_CHART_DATA_SUCCESS } from "./actionType";
import { SPEND_CHART_DATA_FAILED } from "./actionType";
import { SPEND_APPLICATION_DATA_FAILED } from "./actionType";

const initialState = {
    charts :null,
    loading : true,
    application:null
}


const SpendReducer = (state=initialState,action) => {
    switch(action.type)
    {
        case SPEND_CHART_DATA_SUCCESS:
            return {
                ...state,
                charts : action.data,
                loading:false
            }
        case SPEND_CHART_DATA_FAILED:
            return {
                ...state,
                charts:null,
                loading:true
            }
        case SPEND_APPLICATION_DATA_SUCCESS:
            return {
                ...state,
                application:action.data,
                loading:false
            }
        case SPEND_APPLICATION_DATA_FAILED:
            return {
                ...state,
                application:null,
                loading:true
            }
        default:
            return state
    }
}

export default SpendReducer;
