import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import axios from 'axios';
import {
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Row,
} from "reactstrap";
import { Tooltip } from "antd";
import { withRouter,useHistory} from "react-router-dom";
import { SPEND_OVERVIEW_REQUEST } from "store/spendOverView/actions";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Cards


import { getProjects as onGetProjects } from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setDefaultLocale } from "react-datepicker";
import SpendChart from "./SpendChart";
import { compose } from "redux";
import {connect} from 'react-redux';
import Loader from "utils/Loader";
//import SpendChart from "./SpendChart";



const ProjectsGrid = props => {



  const  history = useHistory()

  const [token, setToken] = useState(JSON.parse(localStorage.getItem("okta-token-storage")))

  const [chartData,setChartData] = useState([])

  const [data,setData] = useState(null)
  
    console.log(history.location.state)

    useEffect(()=>{
      props.SPEND_OVERVIEW_REQUEST(history.location.state)
    },[])


  
    useEffect(()=>{
      if(props.spendOverData)
      {        
        setData(props.spendOverData)
        let charts = props.spendOverData?.charts
        let ArrayChart = Object.values(charts)          
        setChartData(ArrayChart)          
      }
    },[props.spendOverData])
    


  return (
    props.loading ? <Loader /> : 
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Projects Grid | Layerity
          </title>
        </MetaTags>
        <Container fluid>

          
          {/* Render Breadcrumbs */}        
          <Breadcrumbs  breadcrumbItem="spend-overview" to="/spend"  data={[{name:'Spend',path:"/spend"}, {name:'spend-overview',path:"#"}]}/>

          <Row>
            <Col xl="6" className="tootip_img"  >
              <Tooltip title={data?.info?.name} >
                {
                  data?.info?.icon_url && <img src={`https://api.layerity.com${data?.info?.icon_url}`} alt="" className="avatar-sm" style={{ width: '180px', maxWidth: '100%', height: 'auto' }} />
                }                
              </Tooltip>
            </Col>
            <Col xl="6" className="user">
              <div className="site-card-wrapper">
                <Row gutter={16}>
                  <Col md="4" sm="4">
                    <Card>
                      <span style={{ "color":"#ff1493","fontweight":"900", textTransform: 'upperCase' }}>users</span>
                      <h3>{data?.info?.active_users}</h3>
                    </Card>
                  </Col>
                  <Col md="4" sm="4">
                    <Card>
                      <span style={{ "color":"#ff1493","fontweight":"900", textTransform: 'upperCase' }}> expenses</span>
                      <h3>{data?.info?.expenses}</h3>
                    </Card>
                  </Col>
                  <Col md="4" sm="4">
                    <Card>
                      <span style={{ "color":"#ff1493","fontweight":"900", textTransform: 'upperCase' }}>Expires On</span>
                      <h3>{data?.info?.expires_on}</h3>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
           <Row style={{marginTop:30}}>
           {
           chartData?.length>0 && chartData?.map((item,index) =>{             
             return (
               <Col key={index} xl={4} md={4} sm={12}>
               <SpendChart 
                  label = {item.title}            
                  description = {item?.description}
                  labels = {item?.bar_data?.labels}
                  data = {item?.bar_data?.datasets[0]?.data}
                  borderWidth={item?.bar_data?.datasets[0]?.borderWidth}
                  backgroundColor="midnightblue"
               />
               </Col>
             )
           })         
           }
           </Row>
           <Row style={{ justifyContent: 'space-between' }} className="twobox">
            <Col xl={6} >
             <div className="box" style={{ height:'100%', border: '1px solid rgb(227 227 227)', borderRadius: '20px', padding: '15px', marginTop: '10px' }}>
              <h2 style={{ textTransform: 'upperCase', fontSize: '20px' }}><i className="fa fa-info-circle" aria-hidden="true" style={{ fontSize: '15px', color: '#aaa' }}></i> Owner </h2>
              <p style={{color:'black'}}>{data?.info?.owner}</p>
              </div>
            </Col>
            <Col xl={6}>
            <div className="box" style={{  height:'100%',border: '1px solid rgb(227 227 227)', borderRadius: '20px', padding: '15px', marginTop: '10px' }}>
              <h2 style={{ textTransform: 'upperCase', fontSize: '20px' }}> <i className="fa fa-info-circle" aria-hidden="true" style={{ fontSize: '15px', color: '#aaa' }}></i> about </h2>
              <p style={{color:'black'}}>{data?.info?.description}</p>
           </div>
            </Col>
          </Row>                               
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  spendOverData :state.SpendOverViewReducer.data,
  loading :state.SpendOverViewReducer.loading
})

const mapDispatchToProps = {
  SPEND_OVERVIEW_REQUEST:SPEND_OVERVIEW_REQUEST 
}


export default compose(withRouter , connect(mapStateToProps,mapDispatchToProps))(ProjectsGrid);




